<template>
  <div>
    <div class="question">
      <div class="explain-module">
        <div class="explain-module-shape"></div>
        <div class="explain-module-shape2"></div>
        <div class="explain-module-title">
          <div class="title-container">
            <section>
              <h4>支持</h4>
              <h1>常见问题（FAQ）</h1>
            </section>
          </div>
        </div>
<!--        <div class="explain-module-text">-->
<!--          <div class="text-container">-->
<!--            <p><strong>虚幻引擎是一套完整的创作工具套件，可用于游戏开发、建筑及汽车可视化、线性影视内容创作、广播及现场活动制作、培训及模拟，以及其他实时应用。</strong></p>-->
<!--            <p>-->
<!--              虚幻引擎可免费下载，且功能完备，开箱即用，并提供完整的源代码访问。<br><br>-->
<!--              根据<a href="">标准版EULA</a>，虚幻引擎可被免费用于学习及开发内部项目；它还允许您免费分发多种商业项目（无需向Epic Games支付任何费用），包括交付给客户的定制项目、线性内容（比如电影和电视节目），以及所有非营利性产品，或是营收低于分成线的产品。<br><br>-->
<!--              只有当您发行的现成产品整合了虚幻引擎代码（比如电子游戏），且该产品生命周期内的营收超过了100万美元，才只需要支付5%的分成费用。换言之，这前100万美元的营收是无需支付分成的。-->
<!--            </p>-->
<!--            <p>-->
<!--              此外，您还可以选择定制许可。欢迎<a href="">联系我们</a>以了解详情。-->
<!--            </p>-->
<!--            <p>-->
<!--              获得虚幻引擎，获得所有引擎功能、集成工具的完整套件、及整个引擎的C++源码。您将会发现文档、教程、支持资源，以及海量免费内容，包括模板、示例游戏及完整项目，以快速着手制作您想创建的任何内容！-->
<!--            </p>-->
<!--            <p>-->
<!--              Epic将定期发布新版本，包括更新、功能改进、社区贡献及缺陷修复。并且我们也会通过GitHub分享实时变更的代码。-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="explain-module-slider">-->
<!--          <div class="slider-container">-->
<!--            <span></span>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="question-module">
        <div class="question-module-container">
          <div class="container-title">
            <div class="container-title-left">
              <h2>问题列表</h2>
            </div>
            <div class="container-title-right" @click="onExpandAll">
              <span>{{ isExpandAll?'全部关闭':'全部展开' }}</span>
            </div>
          </div>
          <div class="container-list">
            <div class="container-list-container" v-for="item in faqList" :key="item.id">
              <div class="container-ask" @click="item.isOpen = !item.isOpen">
                <div class="container-ask-left">
                  <h3>{{ item.issue }}</h3>
                </div>
                <div class="container-ask-right" :class="item.isOpen?'minus':''"></div>
              </div>
              <div class="container-answer" :class="item.isOpen?'container-answer-develop':'container-answer-close'">
                <div class="container-answer-area" id="faq-text" v-html="item.answers"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/utils/api";

export default {
  data () {
    return {
      isOpen: false,
      faqList: [],
      isExpandAll: false,
    }
  },
  mounted() {
    this.getFaqList();
  },
  methods: {
    getFaqList() {
      api.getFaqData().then((res) => {
        if (res.data.code === 200) {
          this.faqList = res.data.data.records.map(item => {
            if (!item.isOpen) {
              item.isOpen = false;
              return item;
            } else {
              return item;
            }
          })
        }
      })
    },
    onExpandAll() {
      if (this.isExpandAll) {
        this.isExpandAll = false;
        this.faqList.forEach(item => {
          item.isOpen = false;
        })
      } else {
        this.isExpandAll = true;
        this.faqList.forEach(item => {
          item.isOpen = true;
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
//pc端
@media only screen and (min-device-width: 700px) {
  .question {
    width: 100%;
    min-height: 100vh;
    background: rgb(219, 222, 227);
    margin-top: 52px;
    position: relative;
    overflow: hidden;

    .explain-module {
      width: 100%;
      position: relative;

      .explain-module-shape {
        width: 400px;
        height: 400px;
        background: linear-gradient(135deg, rgb(75, 230, 251) 15%, rgb(117, 155, 253) 85%) 0% 0% / cover no-repeat;
        border-radius: 9999px;
        filter: blur(200px);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.6;
        z-index: 10;
      }

      .explain-module-shape2 {
        width: 400px;
        height: 400px;
        background: linear-gradient(135deg, rgb(75, 230, 251) 15%, rgb(117, 155, 253) 85%) 0% 0% / cover no-repeat;
        border-radius: 9999px;
        filter: blur(200px);
        position: absolute;
        top: 100px;
        right: 0;
        opacity: 0.6;
        z-index: 10;
      }

      .explain-module-title {
        width: 100%;
        padding-top: 10vh;
        box-sizing: border-box;
        position: relative;

        .title-container {
          max-width: 1456px;
          margin: 0 auto;
          padding: 0 64px;
          box-sizing: border-box;

          section {
            width: 80%;
            max-width: 864px;
            margin: 0 auto;
            text-align: center;

            h4 {
              color: rgb(20, 20, 20);
              font-weight: 700;
              font-size: 20px;
              line-height: 20px;
              letter-spacing: 4px;
            }

            h1 {
              margin-top: 32px;
              color: rgb(20, 20, 20);
              font-weight: 300;
              font-size: 66px;
              line-height: 74px;
              letter-spacing: -1px;
            }
          }
        }
      }

      .explain-module-text {
        max-width: 1456px;
        margin: 32px auto 0;
        padding: 0 64px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;

        .text-container {
          max-width: 66.66%;
          flex-basis: 66.66%;
          flex-grow: 0;
          padding: 12px;
          box-sizing: border-box;

          p {
            margin: 16px 0;
            color: rgb(20, 20, 20);
            font-size: 16px;
            letter-spacing: 0.5px;

            a {
              color: rgb(0, 87, 217);
            }
          }
        }
      }

      .explain-module-slider {
        min-height: 40px;
        position: relative;
        transform: translateY(0%);
        z-index: 1;

        .slider-container {
          width: 2px;
          height: 72px;
          margin: 0 auto;
          position: relative;
          text-align: center;

          span {
            width: 2px;
            height: 0;
            content: "";
            background: rgb(0, 0, 0);
            display: block;
            position: absolute;
            top: -72px;
            left: 0;
            animation: iikemT 2s infinite cubic-bezier(0, 0.42, 0.2, 0.99) running;
          }

          @keyframes iikemT {
            0% {
              top: 0;
              height: 0;
            }
            40% {
              top: 0;
              height: 144px;
            }
            85% {
              top: 144px;
              height: 0;
            }
            100% {
              top: 144px;
              height: 0;
            }
          }
        }
      }
    }

    .question-module {
      max-width: 1456px;
      margin: 150px auto 0;
      padding: 0 64px 96px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: center;

      .question-module-container {
        max-width: 80%;
        flex-basis: 80%;
        flex-grow: 0;
        margin: 0 auto;
        padding: 12px;
        box-sizing: border-box;

        .container-title {
          width: 100%;
          padding-bottom: 24px;
          border-bottom: 1px solid rgb(56, 59, 80);
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .container-title-left {
            max-width: 100%;
            flex-grow: 1;
            flex-basis: 0;

            h2 {
              color: rgb(0, 0, 0);
              font-weight: 300;
              font-size: 34px;
              line-height: 42px;
            }
          }

          .container-title-right {
            max-width: none;
            flex-grow: 0;
            flex-basis: auto;

            span {
              padding-bottom: 4px;
              background-image: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
              background-position: 0 100%;
              background-repeat: no-repeat;
              background-size: 100% 2px;
              color: #141414;
              font-weight: 500;
              letter-spacing: 2px;
              font-size: 13px;
              cursor: pointer;
            }
          }
        }

        .container-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;

          .container-list-container {
            width: 100%;
            border-bottom: 1px solid rgb(56, 59, 80);

            .container-ask {
              width: 100%;
              margin: 32px 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              .container-ask-left {
                display: flex;
                flex-grow: 1;
                margin-right: 24px;

                h3 {
                  user-select: none;
                  color: rgb(20, 20, 20);
                  font-weight: 400;
                  font-size: 26px;
                  line-height: 34px;
                }
              }

              .container-ask-right {
                width: 25px;
                height: 25px;
                margin-right: 13px;
                position: relative;
              }

              .container-ask-right::before, .container-ask-right::after {
                content: "";
                background-color: rgb(0, 87, 217);
                position: absolute;
                transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
              }

              .container-ask-right::before {
                width: 25px;
                height: 3px;
                top: 11px;
                left: 0;
              }

              .container-ask-right::after {
                width: 3px;
                height: 25px;
                top: 0;
                left: 11px;
                transform-origin: center center;
              }

              .minus::after {
                width: 3px;
                height: 0;
                top: 0;
                left: 11px;
                transform-origin: center center;
                opacity: 0;
              }
            }

            .container-answer {
              width: 100%;
              overflow: hidden;
              transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

              .container-answer-area {
                width: 100%;
                padding: 0 104px 32px 0;
                box-sizing: border-box;

                p {
                  user-select: none;
                  margin: 16px 0;
                  color: rgb(56, 59, 80);
                  font-size: 16px;
                  letter-spacing: 0.5px;

                  a {
                    color: rgb(0, 87, 217);
                  }
                }
              }
            }

            .container-answer-develop {
              //height: 400px;
              max-height: 500px;
              overflow: visible;
              transition-duration: 200ms;
            }

            .container-answer-close {
              height: 0;
              visibility: hidden;
              transition-duration: 150ms;
            }
          }
        }
      }
    }
  }
}

//移动端
@media only screen and (max-device-width: 700px) {
  .question {
    width: 100%;
    background: rgb(219, 222, 227);
    margin-top: 52px;
    position: relative;
    overflow: hidden;

    .explain-module {
      width: 100%;
      position: relative;

      .explain-module-shape {
        display: none;
      }

      .explain-module-shape2 {
        display: none;
      }

      .explain-module-title {
        width: 100%;
        padding-top: 10vh;
        box-sizing: border-box;
        position: relative;

        .title-container {
          width: 100%;
          margin: 0 auto;
          padding: 0 16px;
          box-sizing: border-box;

          section {
            width: 80%;
            max-width: 864px;
            margin: 0 auto;
            text-align: center;

            h4 {
              color: rgb(20, 20, 20);
              font-weight: 700;
              font-size: 20px;
              line-height: 20px;
              letter-spacing: 4px;
            }

            h1 {
              margin-top: 24px;
              color: rgb(20, 20, 20);
              font-weight: 300;
              font-size: 48px;
              line-height: 56px;
              letter-spacing: -1px;
            }
          }
        }
      }

      .explain-module-text {
        width: 100%;
        margin: 24px auto 0;
        display: flex;
        justify-content: center;

        .text-container {
          max-width: 100%;
          flex-basis: 100%;
          flex-grow: 0;
          padding: 0 16px;
          box-sizing: border-box;

          p {
            margin: 16px 0;
            color: rgb(20, 20, 20);
            font-size: 16px;
            letter-spacing: 0.5px;

            a {
              color: rgb(0, 87, 217);
            }
          }
        }
      }

      .explain-module-slider {
        display: none;
      }
    }

    .question-module {
      width: 100%;
      margin: 0 auto;
      padding-bottom: 56px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: center;

      .question-module-container {
        max-width: 100%;
        flex-basis: 100%;
        flex-grow: 0;
        margin: 0 auto;
        padding: 16px;
        box-sizing: border-box;

        .container-title {
          width: 100%;
          border-bottom: 1px solid rgb(56, 59, 80);
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-end;

          .container-title-left {
            max-width: 100%;
            flex-basis: 100%;
            flex-grow: 0;
            padding-bottom: 24px;
            box-sizing: border-box;

            h2 {
              color: rgb(0, 0, 0);
              font-weight: 300;
              font-size: 30px;
              line-height: 38px;
            }
          }

          .container-title-right {
            max-width: 100%;
            flex-basis: 100%;
            flex-grow: 0;
            padding-bottom: 24px;
            box-sizing: border-box;

            span {
              padding-bottom: 4px;
              background-image: radial-gradient(100% 185.14% at 100% 100%, rgb(52, 195, 255) 0%, rgb(51, 142, 238) 100%);
              background-position: 0 100%;
              background-repeat: no-repeat;
              background-size: 100% 2px;
              color: #141414;
              font-weight: 500;
              letter-spacing: 2px;
              font-size: 13px;
              cursor: pointer;
            }
          }
        }

        .container-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;

          .container-list-container {
            width: 100%;
            border-bottom: 1px solid rgb(56, 59, 80);

            .container-ask {
              width: 100%;
              margin: 16px 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              .container-ask-left {
                display: flex;
                flex-grow: 1;
                margin-right: 16px;

                h3 {
                  user-select: none;
                  color: rgb(20, 20, 20);
                  font-weight: 400;
                  font-size: 22px;
                  line-height: 30px;
                }
              }

              .container-ask-right {
                width: 25px;
                height: 25px;
                margin-right: 16px;
                position: relative;
              }

              .container-ask-right::before, .container-ask-right::after {
                content: "";
                background-color: rgb(0, 87, 217);
                position: absolute;
                transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
              }

              .container-ask-right::before {
                width: 25px;
                height: 3px;
                top: 11px;
                left: 0;
              }

              .container-ask-right::after {
                width: 3px;
                height: 25px;
                top: 0;
                left: 11px;
                transform-origin: center center;
              }

              .minus::after {
                width: 3px;
                height: 0;
                top: 0;
                left: 11px;
                transform-origin: center center;
                opacity: 0;
              }
            }

            .container-answer {
              width: 100%;
              overflow: hidden;
              transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

              .container-answer-area {
                width: 100%;
                padding: 0 54px 16px 0;
                box-sizing: border-box;

                p {
                  user-select: none;
                  margin: 16px 0;
                  color: rgb(56, 59, 80);
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 30px;

                  a {
                    color: rgb(0, 87, 217);
                  }
                }
              }
            }

            .container-answer-develop {
              height: auto;
              //height: 400px;
              overflow: visible;
              transition-duration: 200ms;
            }

            .container-answer-close {
              height: 0;
              visibility: hidden;
              transition-duration: 150ms;
            }
          }
        }
      }
    }
  }
}
</style>
